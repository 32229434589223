// methods
import { getClassroomListNew, getClassroomList, getClassListData, delClassroom } from '@/api/basic';

// components
import { Button, Table, TableColumn, Select, Option } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import classroomDialog from './components/classroomDialog';

let gradeMap = new Map(); // Map存年级班级数据

export default {
  name: 'classroom',
  props: {},
  data() {
    return {
      loading: false,
      listData: {
        list: [],
        pageSize: 10,
        pageIndex: 1,
        total: 0,
      },
      parentName: '',
      gradeId: '-1', // 年级ID
      gradeList: [
        {
          gradeId: '-1',
          gradeName: '全部',
        },
      ], // 年级数据
      classId: '-1', // 班级ID
      classList: [
        {
          classId: '-1',
          className: '全部',
        },
      ],
      isEdit: false, // 是否编辑模式
      visible: false,

      formData: {
        className: '',
        gradeId: '',
        classId: '',
        subjectId: '',
        isCommon: true,
      },
      gradeListDialog: [], // 弹窗年级列表
      classListDialog: [], // 弹窗班级列表
    };
  },
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    basicHeader,
    qlPagination,
    classroomDialog,
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    gradeMap = null;
    gradeMap = new Map();
  },
  methods: {
    init() {
      gradeMap.set('-1', []);
      this.getClassroomListNew();
    },
    getClassroomListNew() {
      let { pageIndex, pageSize } = this.listData;
      getClassroomListNew({
        pageIndex,
        pageSize,
      }).then(
        (res) => {
          let {
            classromPage: { recordCount, data },
            gradeList,
          } = res.result;
          this.listData.total = recordCount;
          this.gradeList = [...this.gradeList, ...gradeList];
          this.gradeListDialog = gradeList;
          this.listData.list = data || [];
        },
        (err) => console.log(err)
      );
    },

    getClassroomList(reset = false) {
      let { pageIndex, pageSize } = this.listData;

      if (reset) pageIndex = this.listData.pageIndex = 1;

      this.loading = true;

      getClassroomList({
        pageIndex,
        pageSize,
        gradeId: this.gradeId === '-1' ? undefined : this.gradeId,
        classId: this.classId === '-1' ? undefined : this.classId,
      })
        .then(
          (res) => {
            let { recordCount, data } = res.result;
            this.listData.total = recordCount;
            this.listData.list = data || [];
          },
          (err) => console.log(err)
        )
        .finally(() => {
          this.loading = false;
        });
    },

    // 年级改变
    async handleGradeChange(id) {
      const classList = await this.getClassListData(id);
      this.classList = [
        {
          classId: '-1',
          className: '全部',
        },
        ...classList,
      ];
      this.classId = '-1';
      this.getClassroomList(true);
    },

    // 弹窗年级改变
    async handleDialogGradeChange(id) {
      const classList = await this.getClassListData(id);
      this.formData.classId = '';
      this.classListDialog = classList;
    },

    async getClassListData(id) {
      let classList = [];
      if (gradeMap.has(id)) {
        classList = gradeMap.get(id);
      } else {
        const res = await getClassListData({
          gradeId: id,
        });
        const data = res.result || [];
        gradeMap.set(id, data);
        classList = data;
      }
      return classList;
    },

    // 班级改变
    handleClassChange() {
      this.getClassroomList(true);
    },

    // 新增任教
    handleAdd() {
      this.classListDialog = [];
      this.formData = {
        id: undefined,
        className: '',
        gradeId: '',
        classId: '',
        isCommon: true,
      };
      this.isEdit = false;
      this.visible = true;
    },

    // 编辑
    async handleEdit(item) {
      if (item.typeId !== 1) {
        await this.handleDialogGradeChange(item.gradeId);
      }

      this.formData = {
        id: item.id,
        className: item.name,
        gradeId: item.gradeId,
        classId: item.classId || '',
        isCommon: item.typeId === 1 ? true : false,
      };
      this.isEdit = true;
      this.visible = true;
    },

    handleDel(item) {
      this.$msgbox
        .confirm('你确定删除该教室吗？？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          delClassroom({
            id: item.id,
          }).then((res) => {
            if (res && res.result === 0) {
              let idx = this.listData.pageIndex;
              if (idx > 1 && this.listData.list && this.listData.list.length === 1) {
                idx--;
              }
              this.handleCurrentChange(idx);
            }
          });
        });
    },

    handleSuccess() {
      this.visible = false;
      this.getClassroomList(!this.isEdit);
    },

    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getClassroomList(true);
    },
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getClassroomList();
    },

    formatter(row) {
      return row.typeId === 1 ? '是' : '否';
    },
  },
};
