<template>
  <el-dialog
    custom-class="ql-dialog classroomDialog"
    :title="isEdit ? '编辑教室信息' : '新增教室信息'"
    :visible.sync="visible"
    width="600px"
  >
    <el-form :model="formData" :rules="rules" ref="form" label-width="85px">
      <el-form-item label="教室名称" prop="className" size="small">
        <el-input v-model="formData.className" placeholder="请输入教室名称"></el-input>
      </el-form-item>
      <el-form-item label="公共教室" prop="isCommon" size="small">
        <el-radio-group v-model="formData.isCommon" :disabled="isCanNotChangeCommon">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
        <div class="tips">
          <p class="tips-content">若为公共教室，则该教室的盒子会自动缓存所有班级近15天的业务数据；</p>
          <p class="tips-content">若非公共教室，则缓存所选班级近15天的业务数据。</p>
        </div>
      </el-form-item>
      <el-form-item label="年级" prop="gradeId" size="small">
        <el-select
          v-model="formData.gradeId"
          placeholder="请选择年级"
          @change="handleDialogGradeChange"
          :disabled="formData.isCommon"
          style="width: 100%"
        >
          <el-option
            v-for="item in gradeListDialog"
            :key="item.gradeId"
            :label="item.gradeName"
            :value="item.gradeId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班级" prop="classId" size="small">
        <el-select
          v-model="formData.classId"
          placeholder="请选择班级"
          :disabled="formData.isCommon"
          style="width: 100%"
        >
          <el-option
            v-for="item in classListDialog"
            :key="item.classId"
            :label="item.className"
            :value="item.classId"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleAdd" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Button, Dialog, Input, Form, FormItem, Select, Option, Radio, RadioGroup } from 'element-ui';
import { saveClassroom } from '@/api/basic';
export default {
  name: 'ClassroomDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dialogData: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
    gradeListDialog: Array,
    classListDialog: Array,
  },
  data() {
    const validateGradeId = (rule, value, callback) => {
      if (!this.formData.isCommon && !value) {
        callback(new Error('请选择年级'));
        return;
      }
      callback();
    };
    const validateClassId = (rule, value, callback) => {
      if (!this.formData.isCommon && !value) {
        callback(new Error('请选择班级'));
        return;
      }
      callback();
    };
    const validateClassName = (rule, value, callback) => {
      if (value.trim().length === 0) {
        callback(new Error('教室名称不能为空'));
        return;
      }
      if (value.trim().length > 20) {
        callback(new Error('教室名称过长'));
        return;
      }

      callback();
    };
    return {
      formData: {
        className: '',
        gradeId: '',
        classId: '',
        subjectId: '',
        isCommon: 1,
      },
      rules: {
        gradeId: [
          {
            required: true,
            validator: validateGradeId,
            trigger: 'blur',
          },
        ],
        isCommon: [
          {
            required: true,
          },
        ],
        classId: [
          {
            required: true,
            validator: validateClassId,
            trigger: 'blur',
          },
        ],
        className: [
          {
            required: true,
            validator: validateClassName,
            trigger: 'blur',
          },
        ],
      },
      isCanNotChangeCommon: true, // 是否可以编辑common
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.formData = this.dialogData;
        this.isCanNotChangeCommon = this.dialogData.isCommon && this.isEdit ? true : false;
      }
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  methods: {
    handleAdd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveClassroom({
            id: this.formData.id,
            name: this.formData.className,
            classId: this.formData.isCommon ? undefined : this.formData.classId,
            typeId: this.formData.isCommon ? 1 : 2,
          }).then((res) => {
            if (res && res.result === 0) {
              this.$emit('success');
            }
          });
        } else {
          return false;
        }
      });
    },

    handleCancel() {
      this.visible = false;
      this.$refs.form.clearValidate();
    },

    handleDialogGradeChange(id) {
      this.$emit('gradeChange', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.classroomDialog {
  .tips {
    padding: 8px 12px;
    font-size: 12px;
    color: #808080;
    line-height: 22px;
    background-color: #f8f8f8;
    border-radius: 4px;
  }
}
</style>
